import request from "@/utils/request.js";

export default {
  getUserInfo: (params) => {
    return request({
      baseURL: import.meta.env.VITE_APP_API_TAX,
      url: '/wx/api/getUserInfo',
      method: 'get',
      isData: true,
      params
    })
  },
  getUserInfoV2: (data) => {
    return request({
      baseURL: import.meta.env.VITE_APP_API_TAX,
      url: '/wx/api/loginNew',
      method: 'post',
      isData: false,
      data
    })
  },
}
