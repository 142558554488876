import axios from 'axios'
import netUtils from '@/utils/netUtils'
import {saveAs} from 'file-saver'
// import {ElMessage, ElMessageBox} from 'element-plus'
import {paramEnCrypt} from '@/utils/paramCrypt.js'
import errorCode from '@/constants/errorCode'
import {showDialog} from "vant";
// 是否显示重新登录 保证全局唯一dialog
export const isRelogin = {show: false}

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: import.meta.env.VITE_APP_BASE_API,
  // 超时
  timeout: 60000
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    // const isToken = (config.headers || {}).isToken === false
    // const token = authUtils.getToken()
    // if (token && !isToken) {
    //   config.headers['Access-Token'] = token
    //   config.headers['Authorization'] = 'Bearer ' + token
    // }
    const isCrypt = config.notCrypt ? 'false' : import.meta.env.VITE_APP_PARAM_CRYPT
    // console.log(isCrypt)
    // && !isInWhiteList(config.url)
    if (isCrypt === 'true') {
      config = paramEnCrypt(config)
    } else {
      if (config.method === 'get' && config.params) {
        // get请求映射params参数
        config.url = config.url + '?' + netUtils.objectToUrlParams(config.params)
        config.params = {}
      } else if (config.method === 'post') {
        // post 参数转给data
        // config.data = config.params
        config.params = {}
      }
    }
    // sign(config)
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    console.log(res)
    // 未设置状态码则默认成功状态
    const code = res.data.code || res.data.status || 0
    // 获取错误信息
    const msg = res.data.msg || errorCode[code] || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      // const contentDisposition = res.headers.get('Content-Disposition');
      // let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      // const match = contentDisposition.match(filenameRegex);
      // if (match && match.length > 1) {
      //   const filename = match[1];
      //   const blob = new Blob([res.data])
      //   saveAs(blob, filename)
      //   console.log('文件名是:', filename);
      // } else {
      //   console.log('无法解析文件名');
      // }
      return res
    }

    if (code === 200) {
      return res.config.isData ? res.data.data : res.data
    } else if (code === 201) {
      showLogin()
      return Promise.reject(new Error(msg))
    } else if (code === 500) {
      failMsg(msg)
      return Promise.reject(new Error(msg))
    } else {
      failMsg(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    console.log(error)
    let {message, response} = error
    console.log(response.data.msg)
    if (response.data.msg) {
      message = response.data.msg
    } else if (message === 'Network Error') {
      message = errorCode.error
    } else if (message.includes('timeout')) {
      message = errorCode.timeout
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    failMsg(message)
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download(url, params, filename) {
  return service
    .post(url, params, {
      transformRequest: [
        (params) => {
          return netUtils.tansParams(params)
        }
      ],
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      responseType: 'blob'
    })
    .then(async (data) => {
      const isBlob = await netUtils.blobValidate(data)
      if (isBlob) {
        const blob = new Blob([data])
        saveAs(blob, filename)
      } else {
        const resText = await data.text()
        const rspObj = JSON.parse(resText)
        const msg = rspObj.msg || errorCode[rspObj.code] || errorCode['default']
        failMsg(msg)
      }
    })
    .catch((r) => {
      console.error(r)
      failMsg('下载文件出现错误，请联系管理员！')
    })
}

const failMsg = (msg) => {
  // ElMessage({
  //   message: msg,
  //   type: 'error',
  //   showClose: true,
  //   duration: 3 * 1000
  // })
}

const showLogin = () => {
  if (!isRelogin.show) {
    isRelogin.show = true
    showDialog({
      title: '系统提示',
      message: '登录状态已过期，请重新登录'
    }).then(() => {
      wx?.miniProgram?.reLaunch({
        url: '../index/index'
      })
    })
  }
}

export default service
