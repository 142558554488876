import {defineStore} from 'pinia'

const useKeepViewStore = defineStore('keep-view', {
  state: () => ({
    cachedViews: ['AppIndex']
  }),
  actions: {
    addCachedView(view) {
      if (this.cachedViews.includes(view.name)) return
      if (!view.meta.noCache) {
        this.cachedViews.push(view.name)
      }
    },
    addCachedViewName(name) {
      if (!this.cachedViews.includes(name)) {
        this.cachedViews.push(name)
      }
    },
    delCachedView(view) {
      return new Promise((resolve) => {
        const index = this.cachedViews.indexOf(view.name)
        index > -1 && this.cachedViews.splice(index, 1)
        resolve([...this.cachedViews])
      })
    },
    delCachedViewName(name) {
      return new Promise((resolve) => {
        const index = this.cachedViews.indexOf(name)
        index > -1 && this.cachedViews.splice(index, 1)
        resolve([...this.cachedViews])
      })
    },
    delAllCachedViews() {
      return new Promise((resolve) => {
        this.cachedViews = []
        resolve([...this.cachedViews])
      })
    }
  }
})

export default useKeepViewStore
