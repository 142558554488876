import {sm2} from 'sm-crypto'
// const sm2 = require('sm-crypto').sm2 // 获取sm2对象
const cipherMode = 0 // 选择加密策略，1 - C1C3C2，0 - C1C2C3，默认为1
const sysPublicKey = '040c5de9b3aa6f90236e856d802ad92fc85a3f61d1bfee898878a5d361ded83c36d14eda778b51dbe87b1c82ab3bd2791404bfc79f0821c55842ebde894f75487d' // 系统后台公钥
const uiPrivateKey = '704146c385a15ec649be4817fcdf44843cf1bc8f4ff3d04814010440a0631fc7' // 前端UI私钥
const reqPublicKey = '04A9B263D2CEC554EAA8E652C331E7DEEAD89A75C4B3F17BDD8F6AAC4DC02410206A3C26431B9D77769135CAACDB4977F1A1AE989D402B9F5BF2E41CFE950D2871' // 请求参数公钥
const signPublicKey = '04fb843972d749db936bc15dd89fb365d68112f52f56a9d2f0d1ca79fd8daf9d29c0988f52bfa990d5e9699a7389036edb93344b8c9cdbaafafbad0ba3498c3fb0'

/**
 * SM2加密string数据 登录加密
 * @param {string} data 原始数据
 * @returns {string} 加密后数据
 */
export function getSm2DataHexByString(data) {
  if (data && (typeof data === 'string') && data.constructor === String) {
    return '04' + sm2.doEncrypt(data, sysPublicKey, cipherMode)
  }
  return null
}

/**
 * SM2加密string数据 请求参数加密
 * @param {string} data 原始数据
 * @returns {string} 加密后数据
 */
export function getSm2DataHexByStringReq(data) {
  if (data && (typeof data === 'string') && data.constructor === String) {
    return '04' + sm2.doEncrypt(data, reqPublicKey, cipherMode)
  }
  return null
}

/**
 * SM2加密object数据 登录加密
 * @param {Object} data 原始数据
 * @returns {string} 加密后数据
 */
export function getSm2DataHexByObject(data) {
  if (data) {
    return '04' + sm2.doEncrypt(JSON.stringify(data), sysPublicKey, cipherMode)
  }
  return null
}

/**
 * SM2加密object数据 请求参数加密
 * @param {Object} data 原始数据
 * @returns {string} 加密后数据
 */
export function getSm2DataHexByObjectReq(data) {
  if (data) {
    return '04' + sm2.doEncrypt(JSON.stringify(data), reqPublicKey, cipherMode)
  }
  return null
}

/**
 * SM2解密数据
 * @param {string} dataHex 原始加密数据
 * @returns {string} 解密后数据
 */
export function getSm2DataByString(dataHex) {
  if (dataHex && (typeof dataHex === 'string') && dataHex.constructor === String) {
    dataHex = dataHex.substring(2).toLocaleLowerCase()
    return sm2.doDecrypt(dataHex, uiPrivateKey, cipherMode)
  }
}

/**
 * 十六进制字符串转byte数组
 * @param str
 * @returns {*[]}
 * @constructor
 */
export function str2Bytes(str) {
  if (str.length <= 0) {
    return str
  }
  if (str.length % 2 !== 0) {
    str = '0' + str
  }
  let pos = 0
  let len = str.length
  len /= 2
  const hexA = []
  for (let i = 0; i < len; i++) {
    const s = str.substr(pos, 2)
    const v = parseInt(s, 16)
    hexA.push(v)
    pos += 2
  }
  return hexA
}

/**
 * 获取唯一id
 * @returns {string}
 */
export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

/**
 * SM2加密string数据 签名加密
 * @param {string} data 原始数据
 * @returns {string} 加密后数据
 */
export function getSm2SignHexByString(data) {
  if (data && (typeof data === 'string') && data.constructor === String) {
    return '04' + sm2.doEncrypt(data, signPublicKey, cipherMode)
  }
  return null
}
