<template>
  <router-view v-slot="{Component, route}">
    <!--        <transition mode="out-in" name="scale">-->
    <keep-alive :include="keepViewStore.cachedViews">
      <component
        :is="Component"
        :key="route.path"
      />
    </keep-alive>
    <!--        </transition>-->
  </router-view>

  <van-floating-bubble
    v-model:offset="offset"
    axis="xy"
    :class="{'hide-back': !showBack}"
    class="init-back"
    icon="arrow-left"
    magnetic="x"
    gap="0"
    @click="onClickBack"
  />
</template>

<script setup>
import useKeepViewStore from '@/store/modules/keepView.js'
import {ref, watchEffect} from "vue";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()
console.log(route)
const showBack = ref(false)

watchEffect(() => {
  showBack.value = route.name !== 'AppIndex'
})


const keepViewStore = useKeepViewStore()
console.log(window.screen.height)
const offset = ref({x: 0, y: window.screen.height - 200})
const onClickBack = () => {
  // console.log(router)
  console.log(router.name)
  if (keepViewStore.cachedViews.indexOf(route.name) > 0) {
    keepViewStore.delCachedViewName(route.name)
  }
  router.back()
}

// window.addEventListener('resize', function () {
//   console.log('resize')
//   console.log(document.activeElement.tagName)
//   if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
//     let elements = document.getElementsByClassName('div-bottom-btn')
//     Array.prototype.forEach.call(elements, function (element) {
//       if (element.style.display === "none") {
//         element.style.display = "flex"
//       } else {
//         element.style.display = "none"
//       }
//     })
//   }
// });
</script>

<style lang="scss">
.van-popover__content {
  max-height: 50vh;
  overflow: auto !important;

  .van-popover__action {
    width: inherit;
  }
}

.van-field__label {
  &::before {
    content: '*';
    color: red;
    margin-left: -5px;
    margin-right: 5px;
  }
}

</style>
