import router from './router'
import useUserStore from '@/store/modules/user.js'
import useKeepViewStore from '@/store/modules/keepView.js'
import {showDialog} from 'vant'

router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.name) {
    useKeepViewStore().addCachedViewName(to.name)
  }
  const userStore = useUserStore()
  if (!userStore.userId && to.path === '/') {
    const code = to.query.code || 'test'
    userStore.code = code
    userStore
      .getUserInfo({
        code: code,
        officeCode: to.query.officeCode,
        phoneCode: to.query.phoneCode,
        iv: to.query.iv,
        encryptedData: to.query.encryptedData
      })
      .then(
        () => {
          next()
        },
        (err) => {
          if (err === 203) {
            showDialog({
              title: '登录异常',
              message: '未查询到机构信息'
            }).then(() => {
              wx?.miniProgram?.navigateBack()
            })
          }
        }
      )
      .catch((err) => {
        console.error('用户信息获取失败')
        showDialog({
          title: '登录异常',
          message: '未查询到机构信息'
        }).then(() => {
          wx?.miniProgram?.navigateBack()
        })
      })
    return
  }
  next()
})
