import {defineStore} from "pinia";
import apiUser from "@/apis/apiUser.js";

const useUserStore = defineStore('user', {
  state: () => ({
    code: '',
    userId: '',
    userInfo: {}
  }),
  actions: {
    getUserInfo(params) {
      return new Promise(function (resolve, reject) {
        apiUser.getUserInfoV2(params).then(res => {
          if (res.code === 200) {
            const userStore = useUserStore()
            userStore.userId = res.data.user.id
            // userStore.userId = '1532172851340296192'
            userStore.userInfo = res.data.user
            resolve()
          } else if (res.code === 203) {
            reject(res.code)
          }
        }, (err) => {
          console.error(err)
          reject()
        }).catch((err) => {
          console.error(err)
          reject()
        })
      })
    }
  }
})

export default useUserStore
