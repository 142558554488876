export default {
  /**
   * 参数处理
   * @param {*} params  参数
   *
   * 末尾会多个&，.slice(0, -1)
   */
  tansParams: (params) => {
    let result = ''
    for (const propName of Object.keys(params)) {
      const value = params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value !== null && value !== '' && typeof value !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
              let params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              result += subPart + encodeURIComponent(value[key]) + '&'
            }
          }
        } else {
          result += part + encodeURIComponent(value) + '&'
        }
      }
    }
    return result
  },
  objectToUrlParams(obj) {
    const arr = []
    Object.entries(obj).forEach(([key, value]) => {
      if (value !== null && value !== '' && typeof value !== 'undefined') {
        arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    })
    return arr.join('&')
  },
  blobValidate: async (data) => {
    try {
      const text = await data.text()
      JSON.parse(text)
      return false
    } catch (error) {
      return true
    }
  }
}
