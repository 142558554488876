import { getSm2DataHexByObjectReq, getSm2DataHexByStringReq } from '@/utils/sm2Util'

export function isInWhiteList(url) {
  const reqWhiteList = [
    process.env.VUE_APP_SSO_API + '/login',
    process.env.VUE_APP_SSO_API + '/getTokenByRefreshToken',
    process.env.VUE_APP_SSO_API + '/logout'
  ] // 请求白名单
  const routeWhiteList = ['test'] // 路由白名单
  const sdmap = 'service.sdmap.gov.cn'
  return reqWhiteList.includes(url) || routeWhiteList.includes(window.location.pathname) || url.includes(sdmap)
}

export function paramEnCrypt(config) {
  if (config.method === 'post' || config.method === 'delete' || config.method === 'put') {
    const postType = ['application/json']
    let type = []
    if (config.headers['Content-Type']) {
      type.push(config.headers['Content-Type'])
    } else {
      type = type.concat(postType)
    }
    // JSON
    if (type.includes('application/json')) {
      config.data = {
        data: getSm2DataHexByObjectReq(config.data)
      }
    }
    // FORM
    if (type.includes('application/x-www-form-urlencoded')) {
      config.data = 'data=' + getSm2DataHexByStringReq(config.data)
    }
  }
  // GET
  if (config.method === 'get') {
    // 参数在params
    if (config.params) {
      config.params = {
        data: getSm2DataHexByObjectReq(config.params)
      }
    } else {
      // url拼接参数
      const url = config.url
      const index = url.indexOf('?')
      if (index !== -1) {
        const param = url.substring(index + 1)
        const reqData = 'data=' + getSm2DataHexByStringReq(param)
        config.url = url.substring(0, index + 1) + reqData
      }
    }
  }
  return config
}
