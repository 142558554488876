import {createRouter, createWebHistory, useRouter} from 'vue-router'

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    hidden: true,
    name: 'AppIndex',
    component: () => import('@/views/AppIndex.vue'),
    meta: {
      title: '首页'
    },
    // redirect: '/HomePage',
    _children: [
      {
        path: '/HomePage',
        hidden: true,
        name: 'HomePage',
        component: () => import('@/views/home/HomePage.vue'),
        meta: {
          title: '主页'
        }
      },
      {
        path: '/MessagePage',
        hidden: true,
        name: 'MessagePage',
        component: () => import('@/views/home/MessagePage.vue'),
        meta: {
          title: '消息'
        }
      },
      {
        path: '/UserCenterPage',
        hidden: true,
        name: 'UserCenterPage',
        component: () => import('@/views/home/UserCenterPage.vue'),
        meta: {
          title: '消息'
        }
      }
    ]
  },
  {
    path: '/ClientListPage',
    hidden: true,
    name: 'ClientListPage',
    component: () => import('@/views/client/ClientListPage.vue'),
    meta: {
      title: '客户信息'
    }
  },
  {
    path: '/ContractPage',
    hidden: true,
    name: 'ContractPage',
    component: () => import('@/views/client/ContractPage.vue'),
    meta: {
      title: '合同信息'
    }
  },
  {
    path: '/ProjectPage',
    hidden: true,
    name: 'ProjectPage',
    component: () => import('@/views/client/ProjectPage.vue'),
    meta: {
      title: '项目信息'
    }
  },
  {
    path: '/ReportPage',
    hidden: true,
    component: () => import('@/views/client/ReportPage.vue'),
    meta: {
      title: '风险报告'
    }
  },
  {
    path: '/ClientDetailPage',
    hidden: true,
    component: () => import('@/views/client/ClientDetailPage.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/MineDetailPage',
    hidden: true,
    component: () => import('@/views/client/MineDetailPage.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/NewsContentPage',
    hidden: true,
    component: () => import('@/views/home/NewsContentPage.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/MessageContentPage',
    hidden: true,
    component: () => import('@/views/home/MessageContentPage.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/OfficeSwitchPage',
    hidden: true,
    component: () => import('@/views/home/OfficeSwitchPage.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/PdfViewer',
    hidden: true,
    component: () => import('@/views/common/PdfViewer.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/BasicDetailPage',
    hidden: true,
    component: () => import('@/views/client/BasicDetailPage.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true,
    meta: {
      title: '401'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404'),
    hidden: true
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {top: 0, left: 0}
    }
  }
})
// router.beforeEach((to, from, next) => {
  // document.title = to.meta.title || import.meta.env.VITE_APP_TITLE
  // next()
// })
export default router
